
import axios from "axios";
import { urlBase } from "./API";
import { generalMethods } from "./generalMethods";

// Create Blog
export const createBlog = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/blogs`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Get All Blogs
export const getBlogs = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/blogs`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Delete Single Blogs
export const delBlogs = (id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/blogs/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}



// Update Single Blogs
export const updateBlogs = (data,id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/blogs/${id}`,
        data:data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// NEW BLOG ENDPOINTS

export class BlogEndPoints extends generalMethods{
    constructor(){
     super();
     
    }

    // create a post

    async createCategory(name,description){
      try{
        return await super.apiRequest("POST",'categories',{name,description})
      }
      catch(err){
        console.log(err);
        throw err; // Re-throw error to be handled by caller
    }
    }

    // Get All Categories
    async getAllBlogCategories(){
        try{
            return await super.apiRequest("GET",'categories')
        }
        catch(err){
            console.error(err.message,err)
            throw err; // Re-throw error to be handled by caller

        }
    }

    // Get Category By id
    async getCategoryById(id){
        try{
       return await super.apiRequest("GET",`categories/${id}`)
        }
        catch(err){
            console.error(err.message,err);
            throw err; // Re-throw error to be handled by caller

        }
    }
    //  Update Category
    async updateCategory(id,{name,description}){
        try{
          return await super.apiRequest("PATCH",`categories/${id}`,{name,description})
        }
        catch(err){
            console.log(err);
            throw err; // Re-throw error to be handled by caller


        }
      }
    //   
    async deleteCategory(id){
        try{
          return await super.apiRequest("DELETE",`categories/${id}`)
        }
        catch(err){
            console.log(err);
            throw err; // Re-throw error to be handled by caller
        }
      }  

}

//  POSTS CLASS
export class Posts extends generalMethods {
    constructor(){
      super()
    }

    // Create newPost
    async createPost(title,content,attachments,coverImage,categories){
        try{
    return await super.apiRequest("POST",`posts`,{title,content,attachments,coverImage,categories})
        }
        catch(err){
            console.log(err);
            throw err; // Re-throw error to be handled by caller
        }
    }
    // get

}