import { useEffect, useState, lazy, Suspense, useMemo } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_All_Blogs } from "./actions/blog";
import { ToastContainer } from "react-toastify";
import PageContainer from "./components/layout/PageContainer";

// Lazy Loading للمكونات الكبيرة
const Register = lazy(() => import('./components/shared/Modules/Register'));
const Login = lazy(() => import('./components/shared/Modules/Login'));
const ServicePage = lazy(() => import("./Pages/Services/ServicePage"));
const Employee = lazy(() => import('./components/Admin/Employee/Employee'));
const Timeline = lazy(() => import('./Pages/Client/Dashboard/ServiceDashboard'));
const DashboardClient = lazy(() => import('./Pages/Client/Dashboard/DashboardClient'));
const BlogDetails = lazy(() => import('./Pages/Blog/BlogDetails'));
const HeaderClient = lazy(() => import('./Pages/Client/Dashboard/HeaderClient'));
const Messages = lazy(() => import('./Pages/Client/Dashboard/Messages'));
const HomePage = lazy(() => import('./Pages/Home/HomePage'));
const BlogMain = lazy(() => import('./Pages/Blog/BlogMain'));
const Dashboard = lazy(() => import('./components/dashboard/dashboard'));
const Contact = lazy(() => import('./components/Admin/Contacts/Contact'));
const MainComponent = lazy(() => import("./components/Admin/MainComponent"));
const Requests = lazy(() => import("./components/Admin/Requests/Requests"));
const Projects = lazy(() => import("./components/Admin/MiddlDash/Projects"));
const Blog = lazy(() => import("./components/Admin/Blogs/Blog"));
const Notification = lazy(() => import("./Pages/Client/Dashboard/Notification"));
const Affilate = lazy(() => import('./components/affilation/Affilate'));

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    } catch (error) {
      console.error("Invalid user data in localStorage");
      localStorage.removeItem("user");
    }
  
    // جلب بيانات المدونة عند تحميل التطبيق
    dispatch(get_All_Blogs());
  }, [dispatch]);
  
  return (
    <BrowserRouter>
      <Suspense fallback={<div className="loading-screen">جارٍ التحميل...</div>}>
        <Routes>
          {/* 🏠 المسار العام */}
          <Route path="/" element={<PageContainer />}>
            <Route index element={<HomePage />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="services/:id/:name" element={<ServicePage />} />
            <Route path="blog" element={<BlogMain />} />
            <Route path="blog/:id/:postname" element={<BlogDetails />} />
          </Route>

          {/* 📌 لوحة تحكم العميل */}
          {user?.role === "client" && (
            <Route path="/dashboard/:user/client" element={<DashboardClient />}>
              <Route index element={<Timeline />} />
              <Route path="my-profile" element={<HeaderClient />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notification />} />
            </Route>
          )}

          {/* 🎯 رابط الإحالة */}
          <Route path="/affilate/:id" element={<Affilate />} />

          {/* 🛠️ لوحة تحكم المدير */}
          {user?.role === "admin" && (
            <Route path="/dashboard/:user" element={<Dashboard />}>
              <Route index element={<MainComponent />} />
              <Route path="services-requests" element={<Requests />} />
              <Route path="contacts" element={<Contact />} />
              <Route path="projects" element={<Projects />} />
              <Route path="employee" element={<Employee />} />
              <Route path="blogs-system" element={<Blog />} />
            </Route>
          )}
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
