import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function Loading() {
  return (
    <div className="spinner-container">
          <style jsx>{`
                .spinner-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;
                }
            `}</style>
    <Spinner animation="border" variant="primary" />
</div>
  )
}

export default Loading